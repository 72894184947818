import React from 'react';
import Login from 'containers/Login';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';

const LoginPage = (props) => {
  const { t } = useTranslation('login-page');

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Login {...props} />
    </>
  );
};

export default LoginPage;
